<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'RekanAsuransi',

    metaInfo: { title: 'Asuransi Rekanan' },

    extends: View,

    mixins: [
      LoadSections([
        // 'hero-alt',
        'partner',
        'info',
      ]),
    ],

    // props: {
    //   id: {
    //     type: String,
    //     default: 'partner,
    //   },
    // },
  }
</script>
